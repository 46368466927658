.about {
  display: inline;
}
.about-container {
  margin-top: 20%;
}
.about-container > .row > .col-lg-10 > .row:hover {
  box-shadow: 2px 2px 10px #11a4a7;
}
.about-container > .row > .col-lg-10 > .row > .col-lg-8 {
  display: flex;
  align-items: center;
}
.about-head {
  text-align: center;
  font-size: 40px;
  font-weight: 450;
  color: #11a4a7;
  margin-bottom: 100px;
}
.about-sub {
  font-family: 'Oswald';
  font-size: 50px;
  font-weight: 600;
  color: #1e2525b9;
  margin-bottom: 10%;
  letter-spacing: -2px;
  text-align: left;
  border-right: 2px solid #1e2525b9;
  margin-top: 10%;
  padding-right: 10%;
  margin-left: 5%;
}
.about-text {
  align-items: center;
  padding-right: 5%;
  color: #1e2525d0;
  margin-left: 5%;
  font-size: 25px;
  font-weight: 300;
  white-space: pre-line;
}
.block {
  margin-top: 30%;
  background-color: #11a4a7;
  height: 200px;
}
.col-2 {
  background-color: #11a4a7;
  padding-bottom: 10%;
}
.contact {
  display: grid;
}
.contact-container {
  padding-top: 50%;
  margin-bottom: 40%;
}
.contact-container > .row > .col-lg-6 {
  text-align: center;
  margin-bottom: 5%;
  background-color: white;
  box-shadow: 1px 1px 10px #a9a9a9a7;
  padding-bottom: 5%;
}
.contact-text {
  margin-top: 5%;
  font-family: 'Open Sans', normal;
  font-weight: 100;
  font-size: 20px;
  color: #1e2525b9;
}
.contact-text > p {
  margin-top: 5%;
  font-weight: 100;
}
.container {
  text-align: center;
  margin: none;
}
.fade-in {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
}
.fade-in.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.fineprint {
  font-family: 'Oswald';
  font-size: 15px;
  margin-left: 5%;
  color: #1e2525b9;
  display: flex;
}
.fineprint > p {
  margin-left: 2%;
  transition-duration: .5s;
}
.fineprint > p:hover {
  transform: scale(200%);
  background-color: white;
  color: #11a4a7;
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
.font-link {
  font-family: 'Open Sans', normal;
}
.header {
  font-family: 'Oswald';
  font-size: 60px;
  font-weight: 700;
  color: #dedede8e;
  letter-spacing: -5px;
}
.header-sub {
  font-family: 'Oswald';
  font-size: 30px;
  font-weight: 600;
  color: #1e4f5343;
  letter-spacing: -2px;
  text-align: center;
  margin-bottom:7%;
}
.headshot {
  width: 50%;
  height: auto;
  margin: none;
  padding: none;
  margin-left: 25%;
  margin-top: -5%;
}
.home-body {
  text-align: center;
  background-color: #fafafa;
  height: 85vh;
  border-radius: 5px;
  border: 1px solid #dedede;
  margin-top: 15%;
  margin-bottom: 15%;
}
.home-container {
  text-align: center;
  margin: none;
  background-color: #11a4a7;
  height: 95vh;
  border: 1.5rem solid #bbeced3f;
  display: flex;
  align-items: center;
}
.home-sub {
  font-family: 'Oswald';
  font-size: 80px;
  font-weight: 600;
  color: #1e2525b9;
  margin-bottom: 20%;
  letter-spacing: -5px;
  text-align: left;
  border-right: 2px solid #1e2525b9;
  margin-top: 2%;
  padding-right: 10%;
  margin-left: 5%;
}
.home-sub-side {
  position: top;
  font-family: 'Oswald';
  font-size: 200px;
  font-weight: 600;
  color: #1e4f5343;
  letter-spacing: -5px;
  writing-mode: vertical-lr;
  margin-top: 25%;
  text-orientation: sideways;
  cursor: default;
}
.home-text {
  vertical-align: middle;
  padding-right: 5%;
  color: #1e2525d0;
  height: 100%;
  margin-left: 5%;
  font-size: 25px;
  font-weight: 300;
  white-space: pre-line;
}
.inner {
  overflow: 0 auto;
  width: 100vw;
}
.main {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 400;
  min-height: 100vh;
  margin: none;
  cursor: default;
}
.navbar {
  background-color: rgb(255, 255, 255);
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1;
  left: 0;
  font-size: large;
  margin-bottom: none;
}
#navbar-link {
  display: flex;
  justify-content: right;
  padding-top: 1%;
  padding-right: .5%;
}
.nav-name {
  font-family: 'Oswald';
  font-weight: 300;
  font-size: 17px;
  text-decoration: none;
  float: left;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 1.5%;
  color: #11a4a7;
}
.nav-text {
  margin-inline: 3%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-decoration: none;
  transition-duration: .5s;
  border-bottom: 3px solid transparent;
  color: rgb(82, 82, 82);
}
.nav-text:visited {
  color: rgb(82, 82, 82);
}
.nav-text:hover {
  color: #11a4a7;
  transform: translate(-3px);
  border-bottom: 2px solid #11a4a7;
}
.nav-text:active {
  border-bottom: 2px solid #11a4a7;
}
p {
  vertical-align: middle;
}
.pic {
  width: 35px;
  height: 100%;
  margin-inline: 2%;
  margin-top: 2%;
  transition-duration: .5s;
}
.pic:hover {
  transform: translateY(-5px);
}
.preview {
  width: 90%;
  height: auto;
  margin-bottom: 5%;
  border-radius: 8px;
}
.project-container {
  padding-top: 40%;
  color: #1e2525d0;
}
.project-container > .row > .col-lg-4 {
  text-align: center;
  font-size: larger;
  margin-bottom: 5%;
}
.project-container > .row > .col-lg-4 > p {
  margin-left: 5%;
  margin-right: 5%;
}
.project-container > .row > .col-4 > li {
  list-style: none;
}
.project-sub {
  text-align: center;
  font-family: 'Oswald';
  font-size: 80px;
  font-weight: 600;
  color: #1e2525b9;
  margin-bottom: 5%;
  letter-spacing: -5px;
  margin-top: 20%;
}
.title {
  font-family: 'Oswald';
  font-size: 35px;
  font-weight: 300;
  color: #1e2525b9;
  text-decoration: none;
  transition-property: 1s;
  display: inline-block;
}
.title:hover {
  color: #11a4a7;
  text-decoration: underline;
}
@media (min-width: 0px) {
  .header {
    font-family: 'Oswald';
    font-size: 60px;
    font-weight: 700;
    color: #dedede8e;
    letter-spacing: -5px;
    transform: scale(1, 1.25);
  }
  .header-sub {
    font-family: 'Oswald';
    font-size: 25px;
    font-weight: 600;
    color: #1e4f5343;
    letter-spacing: -2px;
    text-align: center;
  }
  .headshot {
    width: 70%;
    height: auto;
    padding: none;
    margin-left: 15%;
    margin-top: -5%;
  }
  .nav-text {
    font-size: 15px;
  }
}
@media (min-width: 576px) {
  .about-sub {
    font-size: 80px;
    letter-spacing: -5px;
  }
  .col-2 {
    visibility: visible;
  }
  .contact-container {
    padding-top: 30%;
    margin-bottom: 15%;
  }
  .contact-text {
    font-size: 30px;
  }
  .fineprint > p:hover {
    transform: scale(300%);
    background-color: white;
    color: #11a4a7;
  }
  .header {
    font-size: 130px;
  }
  .header-sub {
    font-size: 50px;
    letter-spacing: -3px;
  }
  .headshot {
    width: 50%;
    height: auto;
    margin: none;
    padding: none;
    margin-left: 15%;
    margin-top: -5%;
  }
  .home-sub-side {
    visibility: visible;
  }
  .nav-name {
    font-size: 20px;
  }
  .project-container {
    padding-top: 35%;
  }
  .project-sub {
    font-size: 100px;
  }
}
@media (min-width: 768px) {
  .about-sub {
    font-size: 80px;
    letter-spacing: -5px;
  }
  .col-2 {
    visibility: visible;
  }
  .contact-container {
    padding-top: 30%;
    margin-bottom: 15%;
  }
  .contact-text {
    font-size: 30px;
  }
  .fineprint > p:hover {
    transform: scale(300%);
    background-color: white;
    color: #11a4a7;
  }
  .header {
    font-size: 150px;
  }
  .header-sub {
    font-size: 50px;
    letter-spacing: -3px;
  }
  .headshot {
    width: 50%;
    height: auto;
    margin: none;
    padding: none;
    margin-left: 25%;
    margin-top: -5%;
  }
  .home-sub-side {
    visibility: visible;
  }
  .nav-name {
    font-size: 20px;
  }
  .project-container {
    padding-top: 30%;
  }
  .project-sub {
    font-size: 100px;
  }
}
@media (min-width: 992px) {
  .about-sub {
    font-size: 80px;
    letter-spacing: -5px;
  }
  .about-text {
    font-size: 22px;
  }
  .col-2 {
    visibility: visible;
  }
  .contact-container {
    padding-top: 10%;
    margin-bottom: 5%;
  }
  .contact-text {
    font-size: 30px;
  }
  .fineprint > p:hover {
    transform: scale(300%);
    background-color: white;
    color: #11a4a7;
  }
  .header {
    font-size: 205px;
  }
  .header-sub {
    font-size: 100px;
    letter-spacing: -5px;
  }
  .headshot {
    width: 50%;
    height: auto;
    margin: none;
    padding: none;
    margin-left: 25%;
    margin-top: -5%;
  }
  .home-sub-side {
    visibility: visible;
  }
  .nav-name {
    font-size: 20px;
  }
  .project-container {
    padding-top: 5%;
  }
  .project-sub {
    font-size: 100px;
  }
}
@media (min-width: 1200px) {
  .about-sub {
    font-size: 65px;
    letter-spacing: -5px;
  }
  .col-2 {
    visibility: visible;
  }
  .contact-container {
    padding-top: 10%;
    margin-bottom: 5%;
  }
  .contact-text {
    font-size: 30px;
  }
  .fineprint > p:hover {
    transform: scale(300%);
    background-color: white;
    color: #11a4a7;
  }
  .header {
    font-size: 225px;
  }
  .header-sub {
    font-size: 100px;
    letter-spacing: -5px;
  }
  .headshot {
    width: 50%;
    height: auto;
    margin: none;
    padding: none;
    margin-left: 25%;
    margin-top: -5%;
  }
  .home-sub-side {
    visibility: visible;
    font-size: 150px;
  }
  .nav-name {
    font-size: 20px;
  }
  .project-container {
    padding-top: 5%;
  }
  .project-sub {
    font-size: 100px;
  }
}

